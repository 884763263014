<template>
  <v-container fluid class="grey lighten-3">

    <v-card class="mb-5">

        <v-tabs v-model="tab" @change="tabChanged">
          <v-tab v-for="item in items" :key="item">{{ item }}</v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">

          <v-tab-item>
            <CountriesList v-if="tab == 0"></CountriesList>
          </v-tab-item>

          <v-tab-item>
            <RegionsList v-if="tab == 1"></RegionsList>
          </v-tab-item>

        </v-tabs-items>

    </v-card>

  </v-container>
</template>

<script>
export default {
  name: 'Geos',

  data () {
    return {
      tab: null,
      items: ['Countries', 'Regions'],
      lsTab: 'geos_tab',
    }
  },

  components: {
    CountriesList: () => import('@/components/geos/CountriesList.vue'),
    RegionsList: () => import('@/components/geos/RegionsList.vue'),
  },

  methods: {
    tabChanged () {
      localStorage.setItem(this.lsTab, JSON.stringify(this.tab))
    },
  },

  created: function () {
    if (localStorage.getItem(this.lsTab)) {
      this.tab = JSON.parse(localStorage.getItem(this.lsTab))
    }
  },
}
</script>

<style scoped>
</style>